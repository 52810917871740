.experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 3%; /* Adjusted for smaller devices */
  background-color: #91dbf2;

  gap: 60px;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.typing-cursor {
  animation: blink 1s infinite;
}

.page-title {
  font-size: 3em;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 3em; /* Increased for smaller devices */
}

.experience-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 800px;
  width: 90%;
  height: auto;

  padding: 35px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 18px 18px 5px 2px rgba(0, 0, 0, 1);
  border: 3px inset black;
  transition: transform 0.2s;
  margin-bottom: 1em; /* Add some space at the bottom */
}

.experience-box:hover {
  transform: scale(1.05);
}

.experience-image {
  max-width: 60%;
  height: auto;
  margin-bottom: 1em; /* Space between image and title */
}

.experience-title {
  font-size: 1.5em;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1em; /* Space between title and description */
}

.experience-description {
  font-size: 1em;
  color: rgb(0, 0, 0);
  text-align: justify;
  margin: 1em; /* Space between image and description, and description and link */

}

.experience-link {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.experience-link:hover {
  color: #007BFF;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

.animated-bg {
  background: linear-gradient(270deg, #91dbf2, #91dbf2);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}
