.App {
  background-color: #240328;
  color: #ffffff;
  font-family: 'JetBrains Mono', 'Fira Code', monospace;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  border: 5px double #3685ec;
  box-sizing: border-box;
}
.invisible-input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.command-output {
  width: 80%;
  max-height: 80%;
  overflow: auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #240328;
  color: #535550;
  overflow-x: hidden;
}

.command-output::-webkit-scrollbar {
  display: none;
}

.command-output {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.command {
  color: #3685ec;
  display: inline-block;
  margin-left: 0.5em
}

.command-entered {
  color: #ffffff;
}

.prompt {
  color: #3685ec;
  display: inline-block;
}

.message {
  color: #ffffff;
  display: block;
  margin-bottom: 1em;
}

.cursor {
  border-left: 10px solid #3685ec;
  animation: blink 1s infinite;
  padding-left: 5px;
}

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

.command-output:focus {
  outline: none;
}

.invisible-input {
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}


.dashes {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

/* Media query for mobile devices */
@media screen and (max-width: 480px) {
  /* iPhone specific styles */

  .App {
    font-size: 14px;  /* Adjusted font size */
  }
  .command-output {
    width: 95%;
    max-height: 95%;
  }
 
  /* Adjusting the line of dashes */
  .dashes {
    font-size: 8px; /* Adjust as needed */
    word-wrap: break-word;
  }
  /* You may need to adjust other components as well */
}