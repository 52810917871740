@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');



.article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #000; /* Darker text color */
    font-size: 18px; /* Increased font size */
    background-color: #fcfcfc; /* Lighter background color */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    font-family: 'Noto Sans', sans-serif; /* New font */

  
}

.image-container {
    max-width: 50%;
    height: auto;

    display: block;
  margin-left: auto;
  margin-right: auto;
  }

.diver {
    max-width: 50%;
    height: auto;
  }

.article-title {
    font-size: 2.5em; /* Increased title font size */
    margin-bottom: 20px;
    text-align: center;
    color: #000; /* Darker title text color */
    font-family: 'Noto Sans', sans-serif; /* New font */
    font-weight: 700; /* Sturdier title font weight */

}

.article-content {
    font-family: 'Noto Sans', sans-serif; /* New font */
    font-weight: 500; /* Semi-bold font weight */
    
}

.article-content ol {
    background-color: #d4e6f4; 
    border-radius: 10px;
    list-style-position: inside; /* Make sure numbers are inside the background box */


    
}


.article-content ul {
    background-color: #d4e6f4; 
    border-radius: 10px;
    list-style-position: inside; /* Make sure numbers are inside the background box */

}

.article-content li li {
    margin-bottom: 0em; /* Reduce the bottom margin on nested list items */
}

.article-content2 ol {
    background-color: #d4e6f4; 
    border-radius: 10px;
    list-style-position: inside; /* Make sure numbers are inside the background box */
}

.article-content2 ul {
    background-color: #d4e6f4; 
    border-radius: 10px;
    list-style-position: inside; /* Make sure numbers are inside the background box */
}


.article-content li {
    margin-bottom: 1em;
    padding: 10px 5px; /* Padding around each list item. Adjust the second value to control horizontal padding */
}



.article-content li:last-child {
    border-bottom: none; /* Remove the bottom border for the last list item */
}


.article-content {
 
}

.article-content p, .article-content ol {
    margin-bottom: 1em;
}

.article-content ol {
    padding-left: 1em;
}

.article-content ul {
    padding-left: 1em;
}

.article-content li {
    margin-bottom: 0.5em;
}

.article-content a {
    color: #007bff;
    text-decoration: none;
}

.article-content a:hover {
    text-decoration: underline;
}

.spacereduce ul {
    line-height:50%
}


blockquote {
    padding-left: 16px;
    margin-left: 16px;
    border-left: 5px solid #9accf2;
}