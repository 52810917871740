.Apper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  height: 100vh;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 16px;
  border: none;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000; /* This is the added line to darken the text */
}

.tabs button.active {
  background-color: #bbb;
}

.articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  width: 100%;
}

.article {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 10px;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease;
}

.article:hover {
  transform: scale(1.05);
}


.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.contact-icons a {
  color: rgb(236, 9, 81); /* change color as needed */
  text-decoration: none;
}

.contact-icons a:hover {
  color: #0077b5; /* change hover color as needed */
}
