@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.ideas-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #91b1f2, #91b1f2);
  font-family: 'Poppins', sans-serif;
}

.ideas-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #3f3d56;
}

.idea-content {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  color: #3f3d56;
  transition: all 0.3s ease-out;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.2);
}

.idea-content:hover {
  transform: scale(1.02);
  box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.2);
}

.idea-content p {
  margin: 0;
  margin-bottom: 10px;
  line-height: 1.5;
}
